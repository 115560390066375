<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

export default {
  page: {
    title: "Historiques des Remboursements",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Historiques des Remboursements",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Remboursements",
          active: true,
        },
      ],
      enAttentes: 0,
      effectues: 0,
      order: [],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "place", sortable: true, label: "Places" },
        { key: "depart", sortable: true, label: "Départ" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "action" },
      ],
      field: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "montant", sortable: true, label: "Montant viré" },
        { key: "date", sortable: true, label: "Date virement" },
        { key: "auto", sortable: true, label: "Mode" },
        { key: "admin", label: "Admin" },
      ],
      showConfirm: false,
      showConfirmT: false,
      idVirement: 0,
      showManuel: false,
      showSucessVirement: false,
      showEchecVirement: false,
      showConfirmFeda: false
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.order = [];
      this.ordersData = [];

      const historique = await apiRequest(
        "GET",
        "admin/remboursements",
        undefined,
        false
      );

      if (historique && historique.data) {
        const filterd = historique.data.virements.filter(filter => filter.conducteur != null && filter.conducteur != undefined)
       filterd.sort((a,b)=>b.id - a.id);
       filterd.forEach((element) => {
          if (element.typeVirement == "REMBOURSEMENT" && element.trajet) {
            var virement = {
              idVirement: element.id,
              trajet:
                element.trajet.lieuDepart.nom +
                " >> " +
                element.trajet.lieuArrivee.nom,
              userId: element.conducteur,
              conducteur: element.conducteur != null ?
                element.conducteur.personne.lastname +
                " " +
                element.conducteur.personne.firstname : "",
              date:
                element.dateVirement == null
                  ? ""
                  : new Date(element.dateVirement).toLocaleString("fr-FR"),
              montant: element.montant,
              moyen: element.moyen,
              depart: new Date(element.trajet.dateHeureDepart).toLocaleString(
                "fr-FR"
              ),
              place: element.place,
              about: element.statut,
              admin:
                element.admin != null ?

                  element.admin.personne.lastname +
                  " " +
                  element.admin.personne.firstname : "",
              auto: element.isAuto == true ? "AUTO" : "MANUEL",
              indicatifTel: element.conducteur != null ? element.conducteur.personne.indicatifTel : "",
            };
            if (element.statut == "PAID") {
              this.order.push(virement);
            }
            if (element.statut == "PENDING") {
              this.ordersData.push(virement);
            }
          }
        });
        this.enAttentes = this.ordersData.length;
        
        this.effectues = this.order.length;
        console.log("this.effectues ",this.order )
      }
    },

    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');
    },
    confirmeVirer(row) {
      this.idVirement = row.idVirement;

      this.showConfirm = true;
    },
    confirmeVirerT(row) {
      this.idVirement = row.idVirement;

      this.showConfirmT = true;
    },
    confirmeVirerM(row) {
      this.idVirement = row.idVirement;

      this.showManuel = true;
    },
    async virer() {
      this.showConfirm = false;

      const virer = await apiRequest(
        "POST",
        "admin/virer",
        { idVirement: this.idVirement },
        false
      );

      if (virer && virer.data) {
        this.showSucessVirement = true;
        this.init();
      } else {
        this.showEchecVirement = true;
        this.init();
      }
    },





    async virementManuel() {
      this.showManuel = false;

      const virer = await apiRequest(
        "POST",
        "admin/virer-manuel",
        { idVirement: this.idVirement },
        false
      );

      if (virer && virer.data) {
        this.showSucessVirement = true;
        this.init();
      } else {
        this.showEchecVirement = true;
        this.init();
      }
    },
    async virerT() {
      this.showConfirmT = false;

      const virer = await apiRequest(
        "POST",
        "admin/virer-tmoney",
        { idVirement: this.idVirement },
        false
      );

      if (virer && virer.data) {


        this.$toast.success("Remboursement effectué avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.init();
      } else {
        this.$toast.error("Echec du remboiursement  !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.init();
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-card header-class="bg-transparent border-primary" class="border border-primary">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Remboursements en attente</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ this.enAttentes }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card header-class="bg-transparent border-success" class="border border-success">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Remboursements effectués</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ this.effectues }}</h1>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Remboursements en attente</h5>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input ">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                    </div>

                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="ordersData" :fields="fields" responsive="sm" :per-page="perPage"
                    :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template v-slot:cell(paymentstatus)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'Paid',
                        'badge-soft-warning': `${row.value}` === 'Unpaid',
                      }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(conducteur)="row">

                      <div @click="goToUserDetail(row.item.userId)">
                        <span>
                          {{ row.item.conducteur }}
                        </span>
                        <i class="fas fa-eye"></i>

                      </div>


                    </template>
                    <template v-slot:cell(action)="row">
                      <a v-if="row.item.indicatifTel != '+228'" href="javascript:void(0);" class="btn btn-success mr-3"
                        v-b-tooltip.hover title="Remboursements" @click="confirmeVirer(row.item)">
                        Auto {{ row.item.indicatifTel }}
                      </a>
                      <a v-if="row.item.indicatifTel == '+228'" href="javascript:void(0);" class="btn btn-danger mr-3"
                        v-b-tooltip.hover title="Virement" @click="confirmeVirerT(row.item)">
                        Auto {{ row.item.indicatifTel }}
                      </a>
                      <a href="javascript:void(0);" class="btn btn-warning mr-3" v-b-tooltip.hover title="Remboursements"
                        @click="confirmeVirerM(row.item)">
                        Manuel
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Remboursements effectués</h5>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <div class="row d-flex justify-content-between align-items-center  mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input ">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                    </div>

                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="order" :fields="field" responsive="sm" :per-page="perPage"
                    :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template v-slot:cell(paymentstatus)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'Paid',
                        'badge-soft-warning': `${row.value}` === 'Unpaid',
                      }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(conducteur)="row">

                      <div @click="goToUserDetail(row.item.userId)">
                        <span>
                          {{ row.item.conducteur }}
                        </span>
                        <i class="fas fa-eye"></i>

                      </div>


                    </template>
                    <template v-slot:cell(action)>
                      <a href="javascript:void(0);" class="btn btn-success mr-3" v-b-tooltip.hover title="Remboursement">
                        <i class="mdi mdi-check font-size-18"></i>
                        Remboursement
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-backdrop" centered v-model="showConfirm"
      title="Voulez-vous vraiment faire un remboursement Automatique à cet utilisateur?" title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirm = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virer">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showManuel"
      title="Voulez-vous vraiment faire un remboursement Manuel à cet utilisateur?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showManuel = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virementManuel">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showConfirmT"
      title="Voulez-vous vraiment faire un remboursement Automatique TMoney à cet utilisateur?" title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirmT = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virerT">Oui</b-button>
      </div>
    </b-modal>


    <b-modal id="modal-backdrop" centered v-model="showConfirmFeda"
      title="Voulez-vous vraiment faire un remboursement Automatique FedaPay à cet utilisateur?" title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirmT = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virerFeda">Oui</b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}
</style>
